





























import './styles/CompositeActionsPopup.scss';
import './styles/CompositeActionsPopupAdaptive.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IWork } from '@store/modules/work-list/interfaces/Interfaces';
import { WorkListActions } from '@store/modules/work-list/Types';
import { IWorkListPopupsState } from '@store/modules/work-list/modules/popups/Interfaces';
import { PopupActions } from '@store/Popup';
import { ITransferPostData } from '@store/modules/work-list/interfaces/ApiRequests';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';

const NSWorkList = namespace('storeWorkList');
const NSWorkListPopups = namespace('storeWorkList/storeWorkListPopups');
const NSProject = namespace('storeProject');

@Component({
    name: 'ConstructMovingPopup',

    components: {
        'BasePopup': () => import('@components/popups/BasePopup.vue'),
        'BaseCheckbox': () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
        'BaseComposite': () => import('@components/BaseComposite/BaseComposite.vue'),
    },
})
export default class ConstructMovingPopup extends Vue {
    $refs!: { basePopup: any }

    @NSWorkList.Getter('workList') workList!: IWork[];

    @NSWorkList.Getter('selectedViewingConstructs') selectedViewingConstructs!: IWork[];

    @NSWorkListPopups.Getter('constructMovingPopupToggler') constructMovingPopupToggler!: boolean;

    @NSProject.Getter('projectId') projectId!: number;

    workToMoveInto = {} as IWork;

    get workToMoveIntoChosen(): boolean {
        return !!Object.keys(this.workToMoveInto).length;
    }

    get selectedConstructsUuids(): string[] {
        return this.selectedViewingConstructs.map((construct) => construct.uuid);
    }

    @Watch('constructMovingPopupToggler')
    watchPopupOpen(): void {
        if (this.constructMovingPopupToggler) this.$refs.basePopup.openPopup();
        else this.$refs.basePopup.closePopup();
    }

    @NSWorkList.Action(WorkListActions.A_TRANSFER_CONSTRUCTS)
    transferConstructs!: (
        payload: ITransferPostData,
    ) => Promise<void>

    @NSWorkListPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IWorkListPopupsState>,
    ) => void;

    selectWorkToMoveInto(work: IWork): void {
        this.workToMoveInto = work;
    }

    dispatchTransferConstructs(): void {
        this.transferConstructs(
            this.formTransferPostData(),
        )
            .then(() => {
                if (this.selectedConstructsUuids.length >= 1) {
                    this.$emit('confirm-construct-moving', this.selectedConstructsUuids.length);
                }
            });
        this.closePopup();
    }

    formTransferPostData(): ITransferPostData {
        return {
            appId: this.projectId,
            constructIds: this.selectedConstructsUuids,
            structureId: this.workToMoveInto.uuid,
        };
    }

    closePopup() {
        this.togglePopup({
            popupToggler: 'constructMovingPopupToggler',
            state: false,
        });
    }
}
